import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import Select from 'react-select';
import { color, font, buttons, breakpoint } from '../components/styles'

const skus = [
  { select: 'sku', value: 'N95', label: 'Halo N95 (PREORDER)' },
  { select: 'sku', value: 'RX', label: 'RX Face Shields' },
  { select: 'sku', value: 'TF', label: 'TF Face Shields' },
];

const quantities = [
  { select: 'quantity', value: 'none', label: 'None' },
  { select: 'quantity', value: '1-10k', label: '1 - 10,000' },
  { select: 'quantity', value: '10k-100k', label: '10,000 - 100,000' },
  { select: 'quantity', value: '100k-1M', label: '100,000 - 1,000,000' },
  { select: 'quantity', value: '1M-100M+', label: '1,000,000 - 100,00,000+'}
];


const FormContainer = styled.div`
  width: 100%;
  margin: auto;

  .text {
    padding: 0 10px;
    margin: 0 auto 1em;
    text-align: center;
    border-bottom: 3px solid #333;
    max-width: 620px;
  }

  .form {
    width: 100%;
    max-width: 600px;
    margin: 10px auto 50px;
    position: relative;
    form {
      display: block;
      width: 100%;
      
      .form_group {
        display: inline-block;
        padding: 15px;
        min-width: 200px;
        &[data-size='100%'] {
          width: 100%;
        }
        &[data-size='50%'] {
          width: 50%;
        }
        &[data-size='33.3%'] {
          width: 33.3%;
        }

        &.product {
          display: flex;
          flex-flow: row wrap;
          width: 100%;
          align-items: center;
          &.last {
            margin-bottom: 15px;
          }
          label {
            opacity: 1;
            padding: 0;
            margin: 0 15px 10px 0;
            width: 170px;
          }
          > div {
            flex: 1;
            min-width: 250px;
          }
        }
      }

      label {
        display: none;
        &.visible {
          display: block;
          padding-left: 12px;
          padding-bottom: 8px;
          opacity: 0.6; 
        }
      }
      select {
        width: 100%;
        font-size: 16px;
        padding: 10px 5px;
      }
      input, textarea {
        font-family: ${font.family.default};
        width: 100%;
        border: none;
        border-bottom: 1px solid #000;
        padding: 12px;
        font-size: 1em;
        transition: border .3s ease-in-out;
        
        &:focus:required:invalid {
          border-color: red;
          box-shadow: none;
          outline: none;
        }
        &:focus:required:valid {
          border-color: ${color.primary};
          box-shadow: none;
          outline: none;
        }
        &:invalid {
          box-shadow: none;
          outline: none;
        }
        &:focus {
          border-color: ${color.primary};
          box-shadow: none;
          outline: none;
        }
      }
      .submit {
        text-align: center;
        margin: 30px auto 0;
      }
    }
    .response {
      color: ${color.text.black};
      text-align: center;
      .message {
        margin: 2em 0 0;
      }
      .status {
        margin: 0 auto;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        position: relative;
      }
      &.success {
        .status {
          animation: none;
          background: ${color.primary};
          &:before, &:after {
            content: '';
            position: absolute;
          }
          &:before {
            border-radius: 0;
            background: transparent;
            transform: rotate(45deg);
            top: 15%;
            left: 30%;
            height: 50%;
            width: 25%;
            border-bottom: 3px solid ${color.white};
            border-right: 3px solid ${color.white};
            z-index: 1;
          }
          &:after {
            background: ${color.primary};
          }
        }
      }
      &.error {
        .status {
          animation: none;
          background: red;
          &:before, &:after {
            position: absolute;
            content: '';
            height: 70%;
            width: 3px;
            background-color: ${color.white};
            border: 0;
            border-radius: 0;
            margin: 0;
            top: 15%;
            left: 44.5%;
          }
          &:before {
            transform: rotate(45deg);
          }
          &:after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }

  &.index {
    margin-bottom: 0;
    padding-bottom: 3em;
    background-color: ${color.black};
    &:after {
      content: "";
      display: block;
      width: calc(100% - 80px);
      height: 1px;
      background-color: ${color.halfTransparent};
      margin: 3em auto 0;
      max-width: 1360px;
    }
    .text {
      h2 {
        color: ${color.text.white};
      }
      h4 {
        color: ${color.text.gray}
      }
    }
    .form {
      .form_group {
        input, textarea {
          background-color: transparent;
          color: ${color.text.white};
          border-color: ${color.white};
          &:focus:required:invalid {
            border-color: red;
            box-shadow: none;
            outline: none;
          }
          &:focus:required:valid {
            border-color: ${color.primary};
            box-shadow: none;
            outline: none;
          }
          &:invalid {
            box-shadow: none;
            outline: none;
          }
          &:focus {
            border-color: ${color.primary};
            box-shadow: none;
            outline: none;
          }
          &::placeholder {
            opacity: .8;
            color: ${color.white};
          }
        }
      }
      .submit {
        button {
          color: ${color.text.black};
          background-color: ${color.white};
          border-color: ${color.white};
          &:hover {
            color: ${color.text.white};
            background-color: ${color.primary};
            border-color: ${color.primary};
          }
        }

        &.loading {
          button {
            &:hover {
              color: ${color.text.black};
              background-color: ${color.white};
              border-color: ${color.white};
            }
          }
          .inner_button {
            margin: 0 auto;
            width: 18px;
            height: 18px;
            border-radius: 25px;
            background: #ffffff;
            background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
            position: relative;
            animation: load 1.4s infinite linear;
            transform: translateZ(0);
            &:before {
              width: 50%;
              height: 50%;
              background: ${color.black};
              border-radius: 100% 0 0 0;
              position: absolute;
              top: 0;
              left: 0;
              content: '';
            }
            &:after {
              background: ${color.white};
              width: 75%;
              height: 75%;
              border-radius: 50%;
              content: '';
              margin: auto;
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              right: 0;
            }
            @-webkit-keyframes load {
              0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
              }
              100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }
            @keyframes load {
              0% {
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
              }
              100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: ${breakpoint.xsmall}){
    .form {
      form {
        .form_group {
          &[data-size='50%'], &[data-size='33.3%'] {
            width: 100%;
          }
        }
      }
    }
  }
`;

export default class CovidSales extends React.Component {
  constructor(props) {
    super(props);

    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderResponse = this.renderResponse.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.encode = this.encode.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
		this.render = this.render.bind(this);

		this.state = {
      formResponse: '',
      formStatus: null,
      loading: false,
      form: {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        phone: '',
        N95: '',
        RX: '',
        TF: '',
        message: '',
        botfield: ''
      }
    }
  }

  componentDidMount(){
    window.location.replace("/covid-sales");
  }

  handleSubmit(e) {
    this.setState({
      formResponse: '',
      formStatus: null,
      loading: true
    })

    console.log(this.encode({ 'form-name': 'covid-sales', ...this.state.form }) )

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({ 'form-name': 'covid-sales', ...this.state.form })
    }).then((res) => {
      this.setState({
        loading: false,
        formResponse: 'Order was sent successfully!',
        formStatus: 'success'
      })
    }).catch((error) => {
      this.setState({
        loading: false,
        formResponse: 'There was an error to submit your order. Please try again later or contact us.',
        formStatus: 'error'
      })
    });

    e.preventDefault();
  }

  encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  handleChange(e) {
    const { form } = {...this.state};
    const currentState = form;
    const { name, value } = e.target;
    currentState[name] = value;

    this.setState({ form: currentState });
  }

  handleSelectChange(selectedOption, select) {
    console.log(selectedOption)

    let selected = []

    // if (selectedOption && select === 'sku') {
    //   selectedOption.forEach(option => {
    //     selected.push(option.value)
    //   });

    //   this.setState({
    //     [select]: selectedOption,
    //     form: {
    //       ...this.state.form,
    //       [select]: selected.toString()
    //     }
    //   });
    // } else {
    this.setState({
      [select]: selectedOption,
      form: {
        ...this.state.form,
        [select]: selectedOption ? selectedOption.value : selectedOption
      }
    });
    // }
  };

  renderResponse() {
    return (
      <div className={`response ${this.state.formStatus}`}>
        <div className='status'></div>
        <div className='message'>{this.state.formResponse}</div>
      </div>
    )
  }

  renderForm() {
    return (
      <form name='covid-sales'
        data-netlify='true'
        data-netlify-honeypot='botfield'
        onSubmit={(e) => this.handleSubmit(e)}>
        <div hidden>
          <label htmlFor='botfield'>Don’t fill this out if you're human:</label>
          <input name='botfield' onChange={(e) => this.handleChange(e)} />
        </div>
        <div className='form_group product' data-size='100%'>
          <label htmlFor='N95' className='visible'>Halo N95 (PREORDER)</label>
          <input type='text' name='N95' value={this.state.form.N95} hidden readOnly/>
          <Select
            value={this.state.N95}
            onChange={selectedOption => this.handleSelectChange(selectedOption, 'N95')}
            options={quantities}
          />
        </div>
        <div className='form_group product' data-size='100%'>
          <label htmlFor='RX' className='visible'>RX Face Shields</label>
          <input type='text' name='RX' value={this.state.form.RX} hidden readOnly/>
          <Select
            value={this.state.RX}
            onChange={selectedOption => this.handleSelectChange(selectedOption, 'RX')}
            options={quantities}
          />
        </div>
        <div className='form_group product last' data-size='100%'>
          <label htmlFor='TF' className='visible'>TF Face Shields</label>
          <input type='text' name='TF' value={this.state.form.TF} hidden readOnly/>
          <Select
            value={this.state.TF}
            onChange={selectedOption => this.handleSelectChange(selectedOption, 'TF')}
            options={quantities}
          />
        </div>
        <div className='form_group' data-size='50%'>
          <label htmlFor='firstName'>First Name:</label>
          <input 
            type='text'
            name='firstName'
            required={true}
            placeholder='First Name'
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='50%'>
          <label htmlFor='lastName'>Last Name:</label>
          <input 
            type='text'
            name='lastName'
            required={true}
            placeholder='Last Name'
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='100%'>
          <label htmlFor='address'>Address:</label>
          <input 
            type='text'
            name='address'
            required={true}
            placeholder='Address'
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='33.3%'>
          <label htmlFor='city'>City:</label>
          <input 
            type='text'
            name='city'
            required={true}
            placeholder='City'
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='33.3%'>
          <label htmlFor='state'>State:</label>
          <input 
            type='text'
            name='state'
            required={true}
            placeholder='State'
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='33.3%'>
          <label htmlFor='zip'>ZIP:</label>
          <input 
            type='text'
            name='zip'
            required={true}
            placeholder='Zip'
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='50%'>
          <label htmlFor='email'>Email:</label>
          <input 
            type='email'
            name='email'
            required={true}
            placeholder='Email'
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className='form_group' data-size='50%'>
          <label htmlFor='phone'>Phone:</label>
          <input 
            type='tel'
            name='phone'
            required={true}
            placeholder='Phone'
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        
        <div className='form_group' data-size='100%'>
          <label htmlFor='message'>Note:</label>
          <textarea 
            type='text'
            name='message'
            placeholder='Note'
            onChange={(e) => this.handleChange(e)}
          ></textarea>
        </div>
        <div className={`submit ${this.state.loading ? 'loading' : ''}`}>
          <buttons.submit type='submit'>
            <div className='inner_button'>{!this.state.loading && 'Submit'}</div>
          </buttons.submit>
        </div>
      </form>
    )
  }


  render() {
    return (
      // <Layout hideCovidMessage='true'>
      //   <SEO title="Covid Sales" />
      //   <FormContainer className={`${this.state.loading ? 'loading' : ''}`}>
      //     <div className='text'>
      //       <h1>Covid-19 Order Form</h1>
      //     </div>
      //     <div className='form'>
      //       { this.state.formStatus && this.renderResponse() }
      //       { !this.state.formStatus && this.renderForm() }
      //     </div>
      //   </FormContainer>
      // </Layout>
      null
    );
  }
}